<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>COMPANY DIRECTORY</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <datatable
      :headers="headers"
      :staticData="items"
      :componentURL="componentURL"
      :searchValue="$route.query ? $route.query.search : ''"
      :search="true"
    ></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";

export default {
  components: { Datatable },

  data() {
    return {
      componentURL: "Employee",

      headers: [
        { text: "Nickname", value: "nickname" },
        { text: "Full Name", value: "full_name" },
        { text: "Title", value: "title" },
        { text: "Phone", value: "phone" },
        { text: "Text", value: "text" },
        { text: "Email", value: "email" },
      ],
      items: [],
    };
  },
};
</script>

<style></style>
